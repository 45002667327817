import React from 'react';
import {
    Alert,
    Backdrop,
	Box,
	Button,
    CircularProgress,
	LinearProgress,
    Slider,
} from '@mui/material';
import { Formik, Form } from 'formik';
import Layout from '../layout';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { Timestamp, } from "firebase/firestore";

const formContainer = {
	width: '100%',
	marginTop: '32px',
};

const UltimatumSenderUserForm = (props) => {
	const { t } = useTranslation();
    const [sliderMarks, setSliderMarks] = React.useState([]);
    const [receivedBid, setReceivedBid] = React.useState(false);
    const [decission, setDecission] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const calculateSliderMarks = (max, increment) => {
        const numberOfMarks = parseFloat(max) / parseFloat(increment);
        const marks = [];
        if (numberOfMarks > 50) {
            return setSliderMarks(marks);
        }
        for (let i = 0; i < numberOfMarks; i+=increment) {
            marks.push({
                value: Math.round((i + Number.EPSILON) * 100) / 100,
                label: `${Math.round((i + Number.EPSILON) * 100) / 100}`
            });
        }
        return setSliderMarks(marks);
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const checkBid = (proposal, value) => {
        const randomWait = Math.floor(Math.random() * 5) + 8; //random number between 8 and 13 seconds

        setLoading(true);
        setTimeout(() => {
            if (proposal < (value*20/100)) {
                setDecission(false)
            } else if (proposal > (value*51/100)) {
                setDecission(true)
            } else {
                setDecission(Math.random() > 0.5);
            }
            setReceivedBid(true);
            return setLoading(false);
          }, randomWait * 1000);
    }

    React.useEffect(() => {
        calculateSliderMarks(props.value, props.increment);
    }, [props.value, props.increment])

	return(
        <Layout>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {decission !== null ?
                <Box>
                    {decission ?
                        <Alert severity="success"><Trans>Great news! your proposal was accepted by the other participant!</Trans></Alert>
                        :
                        <Alert severity="error"><Trans>Sorry, your proposal was rejected by the other participant</Trans></Alert>
                    }
                </Box>
                :
                null
            }
            <Formik
                initialValues = {{
                    UGs_Money_sent: 0,
                    UGs_COST: props.value,
                    UGs_INCREMENT: props.increment,
                    UGs_Answer_receiver: null,
                    start_tm: Timestamp.fromDate(new Date()),
                }}
                validateOnChange={false}
                validateOnBlur={false}
                style={formContainer}
                onSubmit={async (values, actions) => { 
                    values.UGs_Answer_receiver = decission ? 'Yes' : 'No';
                    await props.handleSubmit(values)
                    actions.setSubmitting = false;
                }}
            >
                {({values, setFieldValue, submitForm, isSubmitting}) => (
                    <Form>
                        <Box m={1} textAlign={'left'}>
                            <Slider
                                name="UGs_Money_sent"
                                min={0}
                                max={props.value}
                                step={props.increment}
                                valueLabelDisplay="auto"
                                marks={sliderMarks}
                                value={values.UGs_Money_sent}
                                onChange={(event, value) => setFieldValue('UGs_Money_sent', value)}
                            />
                        </Box>
                        {isSubmitting && <LinearProgress />}
                        {receivedBid ?
                            <Box margin={1}>
                                <Button
                                    sx={{m: 1}}
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    disabled={isSubmitting}
                                    onClick={submitForm}
                                >
                                    <Trans>Continue</Trans>
                                </Button>
                            </Box>
                            :
                            <Box margin={1}>
                                <Button
                                    sx={{m: 1}}
                                    variant="contained"
                                    size="large"
                                    color="secondary"
                                    disabled={isSubmitting}
                                    onClick={() => { checkBid(values.UGs_Money_sent, values.UGs_COST) }}
                                >
                                    <Trans>Send proposal</Trans>
                                </Button>
                            </Box>
                        }
                    </Form>
                )}
            </Formik>
        </Layout>
	);
}

export default UltimatumSenderUserForm;